/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Autocomplete, RenderInputParams } from '@material-ui/lab';
import { TextField, CircularProgress } from '@material-ui/core';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
    }),
);

interface ReportingGroupSelectorProps {
    disabled?: boolean;
    required?: boolean;
    multiple?: boolean;
    // tslint:disable-next-line: no-any
    value?: IAutoCompleteItem | any; // value only available in single select
    error?: boolean;
    onSelection?: (value: IAutoCompleteItem | IAutoCompleteItem[], name: string) => void;
    className?: string;
    helperText?: string;
    name?: string;
    label?: string;
    disablePortal?: boolean;
    disableCloseOnSelect?: boolean;
    onBlur?: React.FocusEventHandler<HTMLDivElement>;
}

interface ReportingGroupSelectorState {
    options: IAutoCompleteItem[];
}

export const ReportingGroupSelector: React.FC<ReportingGroupSelectorProps> = (props) => {
    const classes = useStyles();

    const [state, setState] = useState<ReportingGroupSelectorState>({
        options: [],
    });

    const reportingGroupQuery = useQuery<ReportingGroupData>(REPORTING_GROUP_QUERY, { fetchPolicy: 'cache-first' });

    React.useEffect(() => {
        if (
            !reportingGroupQuery.loading &&
            !reportingGroupQuery.error &&
            reportingGroupQuery.data &&
            reportingGroupQuery.data.firm &&
            reportingGroupQuery.data.firm.reportingGroups &&
            reportingGroupQuery.data.firm.reportingGroups.reportingGroup.length > 0
        ) {
            setState((prevState) => {

                const { reportingGroup } = reportingGroupQuery.data!.firm.reportingGroups;
                return {
                    ...prevState,
                    options: reportingGroup.map((group: ReportingGroup) => {
                        return { label: group.description, value: group.reportingGroupSourceId };
                    })
                };
            });
        }
    // tslint:disable-next-line: align
    }, [reportingGroupQuery.data, reportingGroupQuery.loading, reportingGroupQuery.error]);

    // tslint:disable-next-line: no-any
    const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
        // tslint:disable-next-line: no-console
        console.log('onChange', value);
        if (props.onSelection) {
            props.onSelection(value, props.name ? props.name : 'areaOfLaw');
        }
    };

    const getInputProps = (params: RenderInputParams, isLoading?: boolean) => {
        return {
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                    {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                    {params.InputProps.endAdornment}
                </React.Fragment>
            ),
        };
    };

    return (
        <div className={`${classes.root} ${props.className}`}>
            {props.multiple ? (
                <Autocomplete
                    value={props.value}
                    multiple={true}
                    loading={reportingGroupQuery.loading}
                    disabled={props.disabled}
                    onChange={handleChange}
                    options={state.options}
                    disableCloseOnSelect={props.disableCloseOnSelect}
                    disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                    // tslint:disable-next-line: jsx-no-lambda
                    getOptionLabel={(option: IAutoCompleteItem) => option.label}
                    getOptionSelected={(option, value) => value.value === option.value}
                    onBlur={props.onBlur}
                    // tslint:disable-next-line: jsx-no-lambda
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={props.label}
                            variant="standard"
                            fullWidth={true}
                            margin="none"
                            required={props.required}
                            InputProps={getInputProps(params, reportingGroupQuery.loading)}
                            error={props.error}
                            helperText={props.helperText}
                        />
                    )}
                    // tslint:disable-next-line: jsx-no-lambda
                    renderOption={(option, { inputValue }) => {
                        const matches = match(option.label, inputValue);
                        const parts = parse(option.label, matches);

                        return (
                            <div>
                                {parts.map((part, index) => (
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                        {part.text}
                                    </span>
                                ))}
                            </div>
                        );
                    }}
                />
            ) : (
                <Autocomplete
                    value={props.value}
                    loading={reportingGroupQuery.loading}
                    disabled={props.disabled}
                    onChange={handleChange}
                    options={state.options}
                    disableCloseOnSelect={props.disableCloseOnSelect}
                    disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                    // tslint:disable-next-line: jsx-no-lambda
                    getOptionLabel={(option: IAutoCompleteItem) => option.label}
                    getOptionSelected={(option, value) => value.value === option.value}
                    onBlur={props.onBlur}
                    // tslint:disable-next-line: jsx-no-lambda
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={props.label}
                            variant="standard"
                            fullWidth={true}
                            margin="none"
                            required={props.required}
                            InputProps={getInputProps(params, reportingGroupQuery.loading)}
                            error={props.error}
                            helperText={props.helperText}
                        />
                    )}
                    // tslint:disable-next-line: jsx-no-lambda
                    renderOption={(option, { inputValue }) => {
                        const matches = match(option.label, inputValue);
                        const parts = parse(option.label, matches);

                        return (
                            <div>
                                {parts.map((part, index) => (
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                        {part.text}
                                    </span>
                                ))}
                            </div>
                        );
                    }}
                />
            )}
        </div>
    );
};

export interface ReportingGroupData {
    firm: Firm;
}

export interface Firm {
    reportingGroups: ReportingGroups;
}

export interface ReportingGroups {
    recordCount: number;
    reportingGroup: ReportingGroup[];
}

export interface ReportingGroup {
    id: number;
    description: string;
    guidId: string;
    reportingGroupSourceId: string;
}

const REPORTING_GROUP_QUERY = gql`
    query reportingGroupQuery {
        firm {
            reportingGroups {
                recordCount
                reportingGroup {
                    id
                    description
                    guidId
                    reportingGroupSourceId
                }
            }
        }
    }
`;
